import React from 'react'
import Navbar from '../Components/Navbar';
import { Link } from 'react-router-dom';

export default function Login() {
    let email =React.createRef();
	let password =React.createRef();
  return (
    <>
   
    
    <div>
			<div className="Login-form" style={{textAlign: 'center', marginTop: '60px'}}>
      Enter Your Username and Password Below:<br/><br/>
			<label style={{color:'black'}}>Email:</label><input placeholder="Email" ref={email}/><br/><br/>
			<label style={{color:'black'}}>Password:</label><input type="password" ref={password} placeholder="Password"/><br/><br/>
			<button onClick={()=> alert("No User Found")}>Login</button><br/><br/>
      <div style={{fontSize: '13px'}}>Don't have an account? Apply for one <Link to={'/contact'}>here</Link>.</div>
			</div>
		</div>
    </>
  )
}
