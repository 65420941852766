export const SidebarData =[
    {
        title: 'Home',
        path: '/',
        // icon: <AiIcons.AiFillHome/>,
        cName: 'nav-text'
    },
    // {
    //     title: 'Contact Us',
    //     path: '/contact',
    //     // icon: <GiIcons.GiStoneBlock/>,
    //     cName: 'nav-text'
    // },
    // {
    //     title: 'Products',
    //     path: '/products',
    //     // icon: <GiIcons.GiStoneBlock/>,
    //     cName: 'nav-text'
    // },
    
]