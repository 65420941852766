import React, { useState, useEffect } from 'react';

import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Navbar from '../Components/Navbar';

export default function Contact() {
    const [ValidErr, setRaiseErr] = useState({});
	const [ValidSend, setSentMessage] = useState({});
	const [Phonevalue, setPhoneValue] = useState()
	let NameInput = React.createRef();
	let EmailInput = React.createRef();
  let CompanyInput = React.createRef();
	let Comment = React.createRef();
  return (
    <>
    
    <div id="Contact" className="email-container">
				<div className= "contactTitle">Contact us to start an account with us</div>
				<form className= "form">
				<label className="questions"> Full Name*</label><br/><input ref={NameInput}></input><br/>
				<label  className="questions">Email*</label><br/><input ref={EmailInput}></input><br/>
				<label  className="questions">Phone Number*</label><br/><PhoneInput className="phoneInput" defaultCountry="US" value={Phonevalue} placeholder="Phone Number" name="phone" onChange={setPhoneValue} /><br/>
				<label  className="questions">Company</label><br/><input ref={CompanyInput}></input><br/>
				<label  className="questions">Tell Us About Your Needs</label><br/><textarea className="commentInput"ref={Comment}></textarea><br/>
				{Object.keys(ValidErr).map((key) => {
					return <div style={{ color: "red" }}>{ValidErr[key]}</div>
				})}
				{Object.keys(ValidSend).map((key) => {
					return <div style={{ color: "green" }}>{ValidSend[key]}</div>
				})}
				<button  className="submitButton" type="submit">Submit</button>
				</form>
						
			</div>

    </>
  )
}
