
import React, { useState, useEffect } from 'react';
import Navbar from '../Components/Navbar'
import '../css/landingpage.css';
import PhoneInput from 'react-phone-number-input';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-phone-number-input/style.css';


export default function LandingPage() {
  const [ValidErr, setRaiseErr] = useState({});
	const [ValidSend, setSentMessage] = useState({});
	const [Phonevalue, setPhoneValue] = useState()
	let NameInput = React.createRef();
	let EmailInput = React.createRef();
  let CompanyInput = React.createRef();
	let Comment = React.createRef();
  return (
    <div>
    
      <div className="intro-container">
        <LazyLoadImage className="main-img" src="https://res.cloudinary.com/kcb-software-design/image/upload/v1682362628/GLS/business-technology-and-growth-2022-12-16-01-19-32-utc_jmhm0a.jpg" alt=""/>
       <h1 className='header'>
       We help businesses<br />innovate and grow
        </h1> 
      </div>
    <div className='about-container'>
   <h2 className='whatisseo'>What is SEO? </h2> <br/>
    <span>Organic SEO (Search Engine Optimization) is a digital marketing strategy that focuses on optimizing a website's content and structure to increase its visibility in search engine results pages (SERPs) naturally, without paying for ads. This technique aims to improve the quality and relevance of a website's content to match the user's search intent and the search engine's algorithms. Organic SEO involves various on-page and off-page optimization tactics, such as keyword research, metadata optimization, link building, content creation, and social media marketing. The primary goal of organic SEO is to improve a website's organic traffic, which refers to the number of visitors who find a site through search engine results rather than paid advertisements. By following best practices and providing valuable content, websites can improve their search engine rankings and attract more relevant traffic, ultimately leading to increased conversions and revenue.</span>
    </div>
    <div className='solutions-container'>
    <div className="solutions"><h3>Services</h3><br/>
    <div><b>Website Optimization</b></div><br/>
   <span> We offer a specialized service that provides digital marketing strategies to help businesses increase their website's visibility in search engine results pages (SERPs). SEO services typically include various optimization techniques, such as keyword research, on-page and off-page optimization, link building, content creation, and social media marketing. By employing the latest SEO strategies and techniques, an SEO service can help businesses improve their organic search rankings and attract more relevant traffic to their website. A professional SEO service can help businesses achieve their online marketing goals, such as increasing brand awareness, generating leads, and improving conversions. With the constantly evolving search engine algorithms and competition, partnering with an experienced SEO service can be an effective way for businesses to stay ahead of the game and achieve long-term success in their online marketing efforts.</span>
   <div style={{marginTop: '30px'}}><b>Organic Traffic</b></div><br/>
   <span>Our newest and most exciting feature is our organic traffic tool for SEO. A huge part of seo is getting that organic traffic from your desired geographical area to increase your SEO score. It is hard getting to the top of google without organic traffic generated from clicks on google. Here at GL Services we have your solution. You tell us what geographical areas you want your business to grow in and we generate those clicks to help your business! We also have a tool to show you your progress since working with us! </span>
  
    </div>
    </div>
    <div className="organic-container">
    <div className="how-header">How does Our Organic Traffic work?</div><br/><br/>
  <div className="step-container imgstep1">
    {/* <LazyLoadImage src="https://res.cloudinary.com/kcb-software-design/image/upload/v1682469199/GLS/handshaking-in-office-2021-08-26-15-29-32-utc_oxpcgy.jpg" alt="" className="step-image"/> */}
     <div className="step-and-description"  style={{color: 'black'}}>
        <p  className="steps">Step 1:</p>
       <b> Make an account with us</b><br/>
     </div>
  </div>
    <div  className="step-container imgstep2">
    {/* <LazyLoadImage src="https://res.cloudinary.com/kcb-software-design/image/upload/v1682469199/GLS/blue-sky-and-cloud-2022-12-15-22-39-29-utc_e1z7d6.jpg" alt="" className="step-image"/> */}
   <div  className="step-and-description"  style={{color: 'black'}}>
      <  p className="steps">Step 2:</p> 
       <b> Connect your Google Cloud Console account</b> <br/>
   </div>
    </div>
   <div  className="step-container imgstep3">
   {/* <LazyLoadImage src="https://res.cloudinary.com/kcb-software-design/image/upload/v1682469199/GLS/searching-browsing-concept-hand-click-search-page-2022-11-04-04-44-49-utc_h1b3kw.jpg" alt="" className="step-image"/> */}
      <div  className="step-and-description"  style={{color: 'black'}}>
        <p  className="steps">Step 3:</p> 
        <b>Pick which words you want us to search by.</b><br/>
      </div>
   </div>
   <div  className="step-container imgstep4">
   {/* <LazyLoadImage src="https://res.cloudinary.com/kcb-software-design/image/upload/v1682469369/GLS/fnger-pointing-on-globe-2022-04-08-10-20-09-utc_njcgwv.jpg" alt="" className="step-image"/> */}
     <div  className="step-and-description" >
        <p  className="steps">Step 4:</p>
        <b>Choose which region you want your organic traffic to come from.</b><br/>
     </div>
   </div>
   <div  className="step-container imgstep5">
   {/* <LazyLoadImage src="https://res.cloudinary.com/kcb-software-design/image/upload/v1682469200/GLS/close-up-of-hand-using-on-white-mouse-and-clicking-2023-01-27-02-08-31-utc_ednong.jpg" alt="" className="step-image"/> */}
      <div  className="step-and-description"  style={{color: 'black'}}>
        <p className="steps">Step 5:</p>
        <b>Pay for the amount of clicks you want and how often you want us to search for your website.</b><br/>
      </div>
   </div>
   <div  className="step-container imgstep6">
   {/* <LazyLoadImage src="https://res.cloudinary.com/kcb-software-design/image/upload/v1682469199/GLS/businessman-in-black-suit-lift-up-the-stair-caree-2022-09-07-15-55-08-utc_p0guh7.jpg" alt="" className="step-image"/> */}
     <div  className="step-and-description"  style={{color: 'black'}}>
        <p className="steps">Step 6:</p> 
       <b> Watch while your SEO score rises and your revenue increases</b>.<br/>
     </div>
   </div>
    </div>
    <div id="Contact" className="email-container">
				<div className= "contactTitle">Contact us to start an account with us</div>
				<form className= "form">
				<label className="questions"> Full Name*</label><br/><input ref={NameInput}></input><br/>
				<label  className="questions">Email*</label><br/><input ref={EmailInput}></input><br/>
				<label  className="questions">Phone Number*</label><br/><PhoneInput className="phoneInput" defaultCountry="US" value={Phonevalue} placeholder="Phone Number" name="phone" onChange={setPhoneValue} /><br/>
				<label  className="questions">Company</label><br/><input ref={CompanyInput}></input><br/>
				<label  className="questions">Tell Us About Your Needs</label><br/><textarea className="commentInput"ref={Comment}></textarea><br/>
				{Object.keys(ValidErr).map((key) => {
					return <div style={{ color: "red" }}>{ValidErr[key]}</div>
				})}
				{Object.keys(ValidSend).map((key) => {
					return <div style={{ color: "green" }}>{ValidSend[key]}</div>
				})}
				<button  className="submitButton" type="submit">Submit</button>
				</form>
						
			</div>

    </div>
    
  )
}
