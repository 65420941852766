import React, {useState, useEffect}from 'react'
import { Link } from 'react-router-dom';
import { SidebarData } from '../config/SidebarData';

// import { CartContext } from '../config/CartContext';
import '../css/navbar.css';

export default function Navbar() {
	const [sidebar, setSidebar] = useState(false)
	// const {getItemsCount} = useContext(CartContext);
	
	const showSidebar = () => setSidebar(!sidebar)
	

  return (
	<body>
		<div className='navbarcontainer'>
			<Link to={"/"} style={{ textDecoration: 'none' }}><img src='https://res.cloudinary.com/kcb-software-design/image/upload/v1682355082/GLS/GlServciesLogo_owjovm.png' alt='' className="title"/></Link>
			
			
				{/* <div ><Link to={"/cart"} className="carty" ><span className="number">{getItemsCount()}</span><FaIcons.FaShoppingCart /></Link></div> */}
	
			
		<div className="login-button"><Link  to={"/login"} style={{ textDecoration: 'none', color:'white' }}>Login</Link></div>
		</div>
		{/* <FaIcons.FaShoppingCart/> */}
	{/* <IconContext.Provider value={{color: "black"}}>
	<div className="navbar">
	{/* <DropDown className="dropdown-menu"/>	 */}
		{/* <Link to="#" className='menu-bars lines'>
		<FaIcons.FaBars onClick={showSidebar}/>
		</Link>
	</div>
	<nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
		<ul className='nav-menu-items' onClick={ showSidebar }>
				<li className='navbar-toggle'>
					<Link to="#" className='menu-bars' color='black'>
						<AiIcons.AiOutlineClose/>
					</Link>
			</li>
			{SidebarData.map((item, index) =>{
				return(
					<li key={index} className={item.cName}>
						<Link to={item.path}>
							{item.icon}
							<span>{item.title}</span>
						</Link>
					</li>
				)
			})}

		</ul>
	</nav> */}
	{/* </IconContext.Provider>  */}
	</body>
  )
}
