import React from 'react'
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import '../css/footer.css';

export default function Footer() {

    const scrollToTop = () => {
		window.scrollTo({
		  top: 0,
		  behavior: "smooth"
		});
	  };
  return (
	<div className="footer">
	
	<div className = "big-container">
		<div className="link-container">
			<div className="generic_links containers">
			{/* <Link to={'/home'} onClick={scrollToTop} className='home_link links'>Home</Link><br/>
			<Link to={'/contact'} onClick={scrollToTop} className='contact_link links'>Contact Us</Link><br/>
			<Link to={'/advantage'} onClick={scrollToTop} className='about_link links'>Advantage</Link><br/>
			<Link to={'/products'} onClick={scrollToTop} className='about_link links'>Advantage</Link><br/> */}
			{/* Us Natural<br/>
			Vancouver BC, Canada<br/>
			V6E1G4<br/> */}
			<Link to={"/"} onClick={()=> scrollToTop()}style={{ textDecoration: 'none' }}><img src='https://res.cloudinary.com/kcb-software-design/image/upload/v1682355082/GLS/GlServciesLogo_owjovm.png' alt='' className="title logoFooter"/></Link>
			
			</div>
			
		<div className="term_links containers">
		
		<Link to={"/terms"} onClick={scrollToTop} className='terms'>Terms & Conditions</Link><br/>
		<Link  to ={"/privacypolicy"} onClick={scrollToTop} className="privacy">Privacy Policy</Link><br/>
		<Link  to ={"/contact"} onClick={scrollToTop} className="privacy">Contact Us</Link>
		{/* <Link  to ={"/privacypolicy"} className="privacy">Investor Privacy Policy</Link> */}
		</div>
		</div>
<div className="copyright">
<img src="https://res.cloudinary.com/kcb-software-design/image/upload/v1666848316/cc_yhw6nf.png" alt="credit card logos"/>
<br/>
	<br/><br/><AiIcons.AiOutlineCopyright/>Copyright 2022 1153583 B.C. LTD. DBA GL Services</div>
	</div>
	</div>
  )
}
