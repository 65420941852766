import { BrowserRouter  as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './Pages/LandingPage';
import Navbar from './Components/Navbar';
import Login from './Pages/Login';
import Contact from './Pages/Contact';
import Footer from './Components/Footer';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';

function App() {
  return (
    <>
    <Router>
      <Navbar/>
		<Routes>
    <Route path="/" element={<LandingPage/>}/>
    <Route path="/login" element={<Login/>}/>
    <Route path="/contact" element={<Contact/>}/>
    <Route path="/terms" element={<Terms/>}/>
    <Route path="/privacypolicy" element={<Privacy/>}/>
    </Routes>
    <Footer/>
    </Router>
    </>
  );
}

export default App;
